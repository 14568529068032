import { Stack } from "@fluentui/react";
import React from "react";
import { AccessTokens } from "../../services/API/BaseService";
import { ExceptionsCausingRequestFailureCard } from "./cards/ExceptionsCausingRequestFailureCard";
import { FailedOperationsCard } from "./cards/FailedOperationsCard";
import { ResponseTimeTrendCard } from "./cards/ResponseTimeTrendCard";
import { RequestCountsCard } from "./cards/RequestCountsCard";
import { ResponseTimebucketsCard } from "./cards/ResponseTimebucketsCard";
import { LastExceptionsCard } from "./cards/LastExceptionsCard";
import { Top10FailedRequestsCard } from "./cards/Top10FailedRequestsCard";

type DocumentsPageProps = {
  accessTokens: AccessTokens;
};

export const DashboardPage: React.FC<DocumentsPageProps> = ({ accessTokens }: DocumentsPageProps) => {
  return (
    <Stack className="adminPage">
      <RequestCountsCard accessTokens={accessTokens} />
      <ResponseTimeTrendCard accessTokens={accessTokens} />
      <Stack horizontal>
        <ExceptionsCausingRequestFailureCard accessTokens={accessTokens} />
        <FailedOperationsCard accessTokens={accessTokens} />
        <Top10FailedRequestsCard accessTokens={accessTokens} />
      </Stack>
      <ResponseTimebucketsCard accessTokens={accessTokens} />
      <LastExceptionsCard accessTokens={accessTokens} />
    </Stack>
  );
};
