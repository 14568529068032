import React, { useMemo, useState, useEffect } from "react";
import { DashboardPage } from "../pages/dashboard/DashboardPage";
import { ParticipantsPage } from "../pages/participants/ParticipantsPage";
import { DocumentsPage } from "../pages/documents/DocumentsPage";
import { ReceiptsPage } from "../pages/receipts/ReceiptsPage";
import { LogsPage } from "../pages/logs/LogsPage";
import { SubscriptionsPage } from "../pages/subscriptions/SubscriptionsPage";
import { SubscriptionParticipantsPage } from "../pages/subscription-participants/SubscriptionParticipantsPage";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import {
  AccessTokens,
  AdminApiScope,
  ApiManagementScope,
} from "../services/API/BaseService";
import { INavLink, INavLinkGroup } from "@fluentui/react";
import {
  BaseLayout,
  NavigationMenu,
  RouteType,
} from "../../../../EASX.Components.Library/ClientApp/src";
import FeaturesPage from "../pages/features/FeaturesPage";

const navLinkGroups: INavLinkGroup[] = [
  {
    name: "Directory",
    links: [
      {
        key: "Participants",
        name: "Participants",
        url: "/participants",
      },
      {
        key: "Subscriptions",
        name: "Subscriptions",
        url: "/subscriptions",
      },
      {
        key: "Features",
        name: "Features",
        url: "/features",
      },
    ],
  },
  {
    name: "Hub",
    links: [
      {
        key: "Documents",
        name: "Documents",
        url: "/documents",
      },
      {
        key: "Receipts",
        name: "Receipts",
        url: "/receipts",
      },
      {
        key: "Logs",
        name: "Logs",
        url: "/logs",
      },
    ],
  },
];

export const MsalTokenWrapper: React.FunctionComponent = () => {
  const { instance, accounts } = useMsal();
  const [accessTokens, setAccessTokens] = useState<AccessTokens>();

  useEffect(() => {
    const accessTokenRequest = {
      scopes: [AdminApiScope],
      account: accounts[0],
    };
    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) =>
        setAccessTokens({ application: accessTokenResponse.accessToken })
      )
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance
            .acquireTokenPopup(accessTokenRequest)
            .then((accessTokenResponse) =>
              setAccessTokens({ application: accessTokenResponse.accessToken })
            )
            .catch((error) => console.log(error));
        }
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (accessTokens?.application && !accessTokens?.apim) {
      const accessTokenRequest = {
        scopes: [ApiManagementScope],
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) =>
          setAccessTokens({
            ...accessTokens,
            apim: accessTokenResponse.accessToken,
          })
        )
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenPopup(accessTokenRequest)
              .then((accessTokenResponse) =>
                setAccessTokens({
                  ...accessTokens,
                  apim: accessTokenResponse.accessToken,
                })
              )
              .catch((error) => console.log(error));
          }
          console.log(error);
        });
    }
  }, [accessTokens]);

  const handleLinkClick =
    (history: any) => (ev?: React.MouseEvent, item?: INavLink) => {
      ev && ev.preventDefault();
      history.push(item?.url ?? "#");
    };

  const renderNavigationMenu = (isDarkTheme: boolean, setDarkTheme: any) => (
    <NavigationMenu
      appName="EAS Admin"
      navLinkGroups={navLinkGroups as any}
      isDarkTheme={isDarkTheme}
      handleLinkClick={handleLinkClick as any}
      setIsDarkTheme={setDarkTheme}
    />
  );

  const pages: RouteType[] = useMemo(
    () =>
      accessTokens?.apim && accessTokens?.application
        ? [
            {
              exact: true,
              path: "/",
              render: () => <DashboardPage accessTokens={accessTokens} />,
            },
            {
              path: "/participants",
              render: () => <ParticipantsPage accessTokens={accessTokens} />,
            },
            {
              path: "/features",
              render: () => <FeaturesPage accessTokens={accessTokens} />,
            },
            {
              path: "/documents",
              render: () => <DocumentsPage accessTokens={accessTokens} />,
            },
            {
              path: "/receipts",
              render: () => <ReceiptsPage accessTokens={accessTokens} />,
            },
            {
              path: "/logs",
              render: () => <LogsPage accessTokens={accessTokens} />,
            },
            {
              exact: true,
              path: "/subscriptions/:id",
              render: () => <SubscriptionParticipantsPage accessTokens={accessTokens} />,
            },
            {
              exact: true,
              path: "/subscriptions",
              render: () => <SubscriptionsPage accessTokens={accessTokens} />,
            },
          ]
        : [],
    [accessTokens]
  );

  return accessTokens?.apim && accessTokens?.application ? (
    <BaseLayout pages={pages} renderNavigationMenu={renderNavigationMenu} />
  ) : null;
};
